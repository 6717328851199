import routes from "@/constants/routes";
import Image from "next/image";

const Hero = () => {
  return (
    <>
      <div className="flex flex-col bg-landing-blue md:flex-row w-full pb-52 md:pb-20 py-8 md:py-16 text-white relative md:px-6 px-5 lg:px-11 xl:px-32 xl:pt-20 xl:pb-20 h-fit justify-between">
        <div className="w-full md:w-auto lg:w-full h-auto">
          <h1>
            <span className="text-3xl font-bold md:text-5xl xl:text-[76px] text-landing-white">
              Turn your rent
            </span>
            <span className="block xl:mb-2 text-3xl font-bold md:text-5xl whitespace-nowrap text-landing-orange xl:text-[76px]">
              into your deposit
            </span>
            <span className="text-3xl font-bold md:text-5xl xl:text-[76px] text-landing-white">
              with Keyzy
            </span>
          </h1>
          <h2 className="text-landing-white font-serif text-xl max-w-xs xs:max-w-md md:max-w-none xl:text-2xl md:mt-4 xl:mt-7 md:leading-8 mt-7 md:w-[19.8rem] xl:w-[30.375rem]">
            Every rent payment you make goes straight to building your deposit so you can own your
            dream home in 2 years
          </h2>

          <div className="flex flex-col md:flex-row">
            <a
              href={routes.howItWorks}
              className="px-4 py-2.5 text-xl h-12 md:h-auto w-full md:w-auto text-center mr-2 text-base font-bold text-white transition duration-300 ease-in-out transform rounded shadow mt-7 xl:mt-16 bg-landing-denim hover:scale-105 cursor-pointer z-40"
            >
              How it works
            </a>
            <a
              href={routes.registerInterest}
              className="px-4 py-2.5 text-xl h-12 md:h-auto w-full md:w-auto text-center mr-2 text-base font-bold text-white transition duration-300 ease-in-out transform rounded shadow mt-7 xl:mt-16 bg-landing-cherry hover:scale-105 cursor-pointer z-40"
            >
              Register your interest
            </a>
          </div>
        </div>

        {/* <a className="min-w-[9rem] flex items-center justify-center md:max-w-fit w-full ml:0 md:w-auto px-4 py-3 text-base font-bold transition duration-300 ease-in-out transform rounded shadow mt-7 xl:mt-12 bg-landing-cherry text-landing-white hover:scale-105"> */}

        <div className="relative h-64 md:h-80 lg:h-96 xl:h-[35rem] w-full mt-16 md:mt-0">
          <Image
            src="/now/keyzy-now-hero-banner.svg"
            alt="Hero image"
            objectFit="contain"
            priority
            layout="fill"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
