import routes from "@/constants/routes";
import Link from "next/link";
import { Props } from "./types";

const Buttons = ({ className }: Props) => {
  return (
    <div
      className={`${className} relative flex flex-col items-start justify-between xl:flex-row md:mt-7`}
    >
      <Link href={routes.howItWorks}>
        <a className="flex-grow block w-full px-4 py-3 mr-3 text-base font-bold text-center text-white transition duration-300 ease-in-out transform rounded shadow cursor-pointer md:w-max bg-landing-denim hover:scale-105">
          See how it works
        </a>
      </Link>
      <Link href={routes.searchHomes}>
        <a className="block min-w-[8.5rem] w-full px-4 py-3 mt-4 text-base font-bold text-center text-white transition duration-300 ease-in-out transform rounded shadow cursor-pointer xl:mt-0 md:w-max bg-landing-orange hover:scale-105">
          Browse homes
        </a>
      </Link>
    </div>
  );
};

export default Buttons;
