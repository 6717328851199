import routes from "@/constants/routes";
import { SubLayout } from "@/containers/layout/LandingLayout";
import Image from "next/image";
import Link from "next/link";

const ReadyToMoveBanner = (): JSX.Element => {
  return (
    <SubLayout>
      <section className="relative z-30 flex flex-col items-center justify-center w-full mx-auto text-white py-9 px-7 md:h-40 xl:h-60 rounded-2xl p-14 bg-landing-orange">
        <h2 className="text-3xl font-bold text-center xl:text-5xl max-w-[360px] md:max-w-fit">
          Ready to move into your dream home now?
        </h2>

        <div className="flex flex-col items-center justify-center text-center md:mt-5 xl:mt-12 md:flex-row">
          <Link href={routes.registerInterest}>
            <a className="min-w-[8rem] flex items-center justify-center w-full h-12 px-4 py-3 mt-4 text-base font-bold transition duration-300 ease-in-out transform rounded-md whitespace-nowrap md:mt-0 md:w-auto bg-landing-blue md:ml-4 xl:ml-9 hover:scale-105">
              Apply today to get your budget
            </a>
          </Link>
        </div>

        <div className="hidden lg:inline-block md:w-16 xl:w-32 absolute md:top-10 xl:top-12 md:-left-2 xl:-left-5 z-10 bg-landing-blue h-[2px]" />
        <div className="hidden lg:inline-block  md:w-12 xl:w-56 absolute  md:top-14 xl:top-20 md:-left-6   xl:-left-40 bg-landing-blue h-[2px]" />
        <div className="hidden xl:inline-block w-52 absolute bottom-12 -right-20 bg-landing-blue h-[2px]" />
        <div className="hidden xl:inline-block w-28 absolute bottom-5 -right-6 bg-landing-blue h-[2px]" />
      </section>
    </SubLayout>
  );
};

export default ReadyToMoveBanner;
