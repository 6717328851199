import Card from "@/atoms/Card";
import SubLayout from "@/containers/layout/LandingLayout/SubLayout";
import Image from "next/image";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import SoldIcon from "@/public/landing/companyFeatures/sold.svg?url";
import EasyKeyzy from "@/public/landing/companyFeatures/easy-keyzy.svg?url";
import NoDeposit from "@/public/landing/companyFeatures/no-deposit.svg?url";
import HorizontalBar from "@/atoms/HorizontalBar";
import VerticalBar from "@/atoms/VerticalBar";

const BenefitsOfKeyzyNow = (): JSX.Element => {
  const { width } = useWindowSize();
  return (
    <SubLayout allowOverflow className="bg-white">
      <section className="px-4 mt-20 bg-white overflow-hidden md:mx-6 lg:px-0 h-6 relative">
        <Image
          src={`/landing/${width < Screen.Tablet_768 ? "mobile-doodle.svg" : "tablet-doodle.svg"}`}
          alt="doodle"
          layout="fill"
          objectFit="contain"
        />
      </section>
      <section className="relative w-full py-16 md:pb-0">
        <div id="why" className="absolute top-0" />
        <h2 className="text-4xl font-bold text-center xl:text-5xl text-landing-blue mb-11 xl:mb-14">
          Benefits of Keyzy
        </h2>

        <div className="block h-64 md:flex md:justify-center md:flex-row mb-11">
          <Card
            title="Fixed rent, no increases"
            subtitle="Payments won’t change during your 2 year lease."
            size="small"
            image={EasyKeyzy}
          />

          <HorizontalBar className="inline-block my-4 md:hidden" />
          <VerticalBar className="hidden md:inline-block md:mx-7 xl:mx-20" />

          <Card
            title="Your rent = your deposit"
            subtitle="100% of your rent gets converted into your deposit."
            size="small"
            image={NoDeposit}
          />
          <HorizontalBar className="inline-block my-4 md:hidden" />
          <VerticalBar className="hidden md:inline-block md:mx-7 xl:mx-20 " />

          <Card
            title="Your future home, today"
            subtitle="Buy the home later at a pre-agreed price."
            size="small"
            image={SoldIcon}
          />
        </div>
      </section>
      <div className="relative h-full bg-white w-full overflow-hidden mb-9 md:mb-0">
        <div className="flex w-full left-0 right-0 m overflow-hidden bg-repeat-x lg:mt-7 h-24 md:h-52 xl:h-48 bg-[url('/intermediaries/city-outline-mobile.svg')]  md:bg-[url('/intermediaries/city-outline-tablet.svg')] xl:bg-[url('/intermediaries/city-outline-desktop.svg')]" />
      </div>
    </SubLayout>
  );
};

export default BenefitsOfKeyzyNow;
