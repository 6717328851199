import routes from "@/constants/routes";
import { SubLayout } from "@/containers/layout/LandingLayout";

const ContactBanner = () => {
  return (
    <SubLayout>
      <section className="relative z-30 flex flex-col items-center justify-center w-full mt-10 mb-5 text-white  py-9 px-7 md:h-44 xl:h-60 rounded-2xl p-14 bg-landing-denim xl:mt-10 md:mb-8 xl:mb-10">
        <h2 className="text-3xl font-bold text-center xl:text-5xl max-w-[360px] md:max-w-fit">
          Let us know if you have any questions
        </h2>

        <div className="flex flex-col items-center justify-center text-center md:mt-5 md:flex-row">
          <p className="my-6 font-serif text-xl font-normal whitespace-nowrap md:my-auto xl:text-2xl">
            Get in touch:{" "}
            <span className="underline">
              <a href="mailto:hello@keyzy.com" target="_blank" rel="noreferrer">
                hello@keyzy.com
              </a>
            </span>
          </p>

          <div className="hidden w-px h-12 bg-white md:mx-4 xl:mx-12 md:inline-block" />

          <p className="font-serif text-xl font-normal xl:text-2xl">Get started today</p>

          <a
            className="min-w-[8rem] flex items-center justify-center w-full h-12 px-4 py-3 mt-4 text-base font-bold md:text-landing-blue lg:text-white transition duration-300 ease-in-out transform rounded-md whitespace-nowrap md:mt-0 md:w-auto bg-landing-orange md:ml-4 xl:ml-9 hover:scale-105 cursor-pointer"
            href={routes.registerInterest}
          >
            I&apos;m interested
          </a>
        </div>
      </section>
    </SubLayout>
  );
};

export default ContactBanner;
