import Card from "@/atoms/CardKeyWorkers";
import NoDeposit from "@/public/icons/noDeposit.svg?url";
import EnableToBuy from "@/public/icons/enableToBuy.svg?url";
import NoMoreVacancies from "@/public/landlords/NoMoreVacancies.svg?url";
import HorizontalBar from "@/atoms/HorizontalBar";
import VerticalBar from "@/atoms/VerticalBar";
import routes from "@/constants/routes";
import Link from "next/link";

const PathToHomeOwnership = (): JSX.Element => {
  return (
    <section className="px-4 md:pt-12 bg-white md:px-16 lg:px-0">
      <h2 className="text-4xl font-bold lg:text-5xl text-landing-blue mb-12 xt-left text-center">
        Get on the path to home ownership now
      </h2>
      <h3 className="font-serif text-xl md:text-2xl lg:text-3xl xl:w-1/2 mb-10 mx-auto text-center">
        It’s renting better, with a chance to buy
      </h3>
      <div className="block lg:h-[388px] lg:flex lg:flex-row lg:justify-center lg:mb-11">
        <div className="flex flex-col lg:flex-col md:flex-row">
          <Card
            title="1. Find your ideal home"
            subtitle=""
            size="big"
            onlyHeading={true}
            image={EnableToBuy}
          />
          <div className="flex lg:w-full items-center justify-center pt-3 md:pt-0 lg:pt-8 ">
            <Link href={routes.registerInterest}>
              <a className="block min-w-[10rem] w-full px-4 py-3 mt-4 md:mt-0  text-base font-bold text-center text-white transition duration-300 ease-in-out transform rounded shadow cursor-pointer xl:mt-0 md:w-max bg-landing-orange hover:scale-105">
                Register interest
              </a>
            </Link>
          </div>
        </div>

        <HorizontalBar className="inline-block my-4 lg:hidden" />
        <VerticalBar className="hidden lg:inline-block " />
        <div className="flex flex-col lg:flex-col md:flex-row">
          <Card
            title="2. Apply with Keyzy"
            subtitle=""
            size="big"
            onlyHeading={true}
            image={NoMoreVacancies}
          />
          <div className="flex lg:w-full items-center justify-center pt-3 md:pt-0 lg:pt-8">
            <Link href={routes.howItWorks + "#3"}>
              <a className="block min-w-[10rem] w-full px-4 py-3 mt-4 md:mt-0  text-base font-bold text-center text-white transition duration-300 ease-in-out transform rounded shadow cursor-pointer xl:mt-0 md:w-max bg-landing-cherry hover:scale-105">
                What you’ll need
              </a>
            </Link>
          </div>
        </div>

        <HorizontalBar className="inline-block my-4 lg:hidden" />
        <VerticalBar className="hidden lg:inline-block" />
        <div className="flex flex-col lg:flex-col md:flex-row">
          <Card
            title="3. Move in and start saving"
            subtitle=""
            size="big"
            onlyHeading={true}
            image={NoDeposit}
          />
          <div className="flex lg:w-full items-center justify-center pt-3 md:pt-0 lg:pt-8">
            <Link href={routes.howItWorks}>
              <a className="block min-w-[10rem] w-full px-4 py-3 mt-4 md:mt-0  text-base font-bold text-center text-white transition duration-300 ease-in-out transform rounded shadow cursor-pointer xl:mt-0 md:w-max bg-landing-denim hover:scale-105">
                How it works?
              </a>
            </Link>
          </div>
        </div>

        <HorizontalBar className="inline-block my-4 lg:hidden" />
      </div>

      <div className="relative h-full bg-white w-full overflow-hidden mb-9 md:mb-0">
        <div className="flex w-full left-0 right-0 m overflow-hidden bg-repeat-x lg:mt-7 h-24 md:h-36 xl:h-48 bg-[url('/intermediaries/city-outline-mobile.svg')]  md:bg-[url('/intermediaries/city-outline-tablet.svg')] xl:bg-[url('/intermediaries/city-outline-desktop.svg')]" />
      </div>
    </section>
  );
};

export default PathToHomeOwnership;
