import { FaqItems } from "./types";

export const faqItems: FaqItems = [
  {
    question: "How much deposit do I need?",
    answer:
      "Unlike most normal rentals, you don’t need a deposit to move into the property. However, you will need a deposit to buy the house at the end of the lease if you want to. The typical minimum deposit required to buy a home is normally 5% or 10%. There are currently some zero deposit mortgages available but as we don’t know their availability at the end of the lease term it would be best to prepare to have to pay some form of deposit. We can provide you details as to a regular monthly amount to save to have the required funds at the end of the lease.",
  },
  {
    question: "What is the minimum household income required?",
    answer:
      "There are no hidden fees on our Keyzy product. Your rental will be fixed for the full duration of your lease, no increases! We don’t charge a deposit or product fee, we don’t charge you a fee if you don’t by the home at the end.",
  },
  {
    question: "What are the basic eligibility criteria?",
    answer:
      "Each property will have a date that it is available from, if you have gone through the approval process and the rental is affordable you can move in from that date. This can be a very fast process in many cases so it’s good to be ready to move when the home is available.",
  },
  {
    question: "What if I want to leave the property?",
    answer:
      "The lease will be for a 2-3 year term. At the end of the term we can sign a new lease if you are unable to buy the home. You are of course free to leave the property if you don’t wish to buy or you can carry on with a new lease.",
  },
];
