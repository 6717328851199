import { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Image from "next/image";
import SubLayout from "@/containers/layout/LandingLayout/SubLayout";

import { formatPrice } from "@/utils/formatPrice";
import { BudgetModel } from "./types";
import { CalculatorDesktop, Buttons, Description } from "./components";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import CalculatorMobile from "./components/CalculatorMobile";
import { useNowCalculatorBudget } from "@/shared/hooks/useNowCalculatorBudget";
import HorizontalBar from "@/atoms/HorizontalBar";

const MIN_ANNUAL_INCOME = 30000;
const MAX_ANNUAL_INCOME = 180000;
const DEFAULT_ANNUAL_INCOME = 70000;

const BudgetCalculator = () => {
  const [annualIncome, setAnnualIncome] = useState<number>(DEFAULT_ANNUAL_INCOME);
  const [keyzyBudget] = useNowCalculatorBudget(annualIncome);
  const [imageUrl, setImageUrl] = useState("/landing/buyingBudget/calc-bg-desktop.svg");
  const { width } = useWindowSize();

  const sliderOnChange = (values: number) => {
    setAnnualIncome(values);
  };

  useEffect(() => {
    if (width < Screen.Tablet_768) setImageUrl("/landing/buyingBudget/calc-bg-mobile.svg");

    if (width >= Screen.Tablet_768) setImageUrl("/landing/buyingBudget/calc-bg-tablet.svg");

    if (width >= Screen.DESKTOP_1280) setImageUrl("/landing/buyingBudget/calc-bg-desktop.svg");
  }, [width]);

  return (
    <div className="md:bg-landing-ivory pb-12 relative h-[68rem] md:h-[43.625rem] lg:h-[40.625rem] text-landing-blue ">
      <div id="calculator" className="absolute top-16 xl:-top-48" />
      <SubLayout allowOverflow>
        <div className="relative flex flex-col justify-between w-full mx-auto max-w-7xl md:flex-row ">
          <div className="relative w-full -top-40 md:-top-24 xl:-top-44 xl:min-w-fit md:w-3/5 lg:1/2 xl:w-3/5">
            <div className="mx-auto xl:w-full">
              <div className="flex flex-col md:px-7 md:w-[28rem] xl:px-12 xl:w-[45.75rem] pb-14 relative z-40">
                {width >= Screen.Tablet_768 && (
                  <Image src={imageUrl} alt="House background" layout="fill" priority />
                )}

                {width < Screen.Tablet_768 && (
                  <div className="absolute z-40 h-48 -left-5 -right-5 -top-8">
                    <Image
                      src={imageUrl}
                      alt="House background"
                      layout="fill"
                      objectFit="cover"
                      priority
                    />
                  </div>
                )}
                <div className="relative z-50 self-center h-56 mt-8 w-80 md:mt-16 md:w-56 md:h-36 xl:w-80 xl:h-56 xl:mt-44">
                  <Image
                    src="/landing/buyingBudget/calculator-house.svg"
                    alt="House"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
                <Description className="mt-4 mb-7 md:hidden max-w-[21.25rem]" />

                <div className="relative flex items-center justify-between mb-4 md:mt-10 xl:mt-0">
                  <div className="mr-3 text-lg font-bold leading-tight text-landing-blue">
                    Annual household income
                  </div>
                  <div className="text-2xl font-bold text-landing-orange">
                    {formatPrice(annualIncome)}
                  </div>
                </div>
                <div>
                  <Slider
                    min={MIN_ANNUAL_INCOME}
                    max={MAX_ANNUAL_INCOME}
                    onChange={sliderOnChange}
                    value={annualIncome}
                    step={1000}
                    trackStyle={{
                      backgroundColor: "#F39237",
                      height: 16,
                      borderRadius: "30px",
                    }}
                    handleStyle={{
                      borderColor: "#272F42",
                      height: 28,
                      width: 28,
                      marginLeft: 0,
                      marginTop: -6,
                      backgroundColor: "#272F42",
                    }}
                    railStyle={{
                      backgroundColor: "#F0F3FD",
                      height: 16,
                      borderRadius: "30px",
                    }}
                  />
                </div>
                <div className="w-full mt-6 ">
                  {width >= Screen.DESKTOP_1280 ? (
                    <CalculatorDesktop keyzyBudget={keyzyBudget as BudgetModel} />
                  ) : (
                    <CalculatorMobile keyzyBudget={keyzyBudget as BudgetModel} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="md:ml-7 xl:ml-20 md:max-w-[24.1rem] xl:max-w-[26rem] md:w-2/5 lg:1/2 xl:w-2/5">
            <div>
              <Description className="hidden xl:w-96 md:inline-block" />
              <Buttons className="-top-40 md:top-0" />
            </div>
          </div>
        </div>
      </SubLayout>
      <HorizontalBar className="inline-block my-4 md:hidden" />
    </div>
  );
};

export default BudgetCalculator;
