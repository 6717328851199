import { useState } from "react";
import ExpandableBox from "@/components/landing-old/ExpandableBox";
import Image from "next/image";

import { faqItems } from "./utils";
import SubLayout from "@/containers/layout/LandingLayout/SubLayout";
import Link from "next/link";
import routes from "@/constants/routes";

const GotQuestions = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const clickHandler = (idx: number) => {
    setActiveIndex((current) => (current === idx ? -1 : idx));
  };

  return (
    <SubLayout className="bg-white" allowOverflow>
      <section className="flex flex-col w-full mx-auto max-w-7xl md:flex-row mt-8 md:mt-12 xl:mt-20">
        <div className="flex md:flex-col">
          <div>
            <h2 className="w-1/2 text-4xl font-bold md:w-auto md: xl:text-5xl text-landing-blue">
              Got questions?
            </h2>

            <div className="absolute left-0 right-0 z-20 flex flex-col px-5 pb-20 md:p-0 md:relative mt-11 md:mt-0 md:w-auto bg-landing-blue md:bg-white pt-9">
              <p className="mt-4 text-base font-normal text-center text-white md:text-left md:text-landing-blue">
                Have a look through our frequently asked questions.
              </p>
              <div>
                <a
                  href={routes.faqs}
                  className="inline-block w-full px-5 py-3 mt-4 text-base font-bold text-center transition duration-300 ease-in-out transform rounded shadow md:text-white md:w-auto bg-brown-400 hover:scale-105 text-landing-blue"
                >
                  View FAQs
                </a>
              </div>
            </div>
          </div>

          <div className="relative w-40 h-28 md:w-80 md:h-56 lg:w-96 lg:h-64 md:mt-20 lg:mt-24">
            <Image src="/landlords/wheel.svg" alt="faq" layout="fill" objectFit="contain" />
          </div>
        </div>

        <div className="relative z-30 w-full mt-48 md:mt-0 md:ml-10">
          {faqItems.map((faq, idx) => (
            <div key={idx}>
              <ExpandableBox
                question={faq.question}
                answer={faq.answer}
                active={activeIndex === idx}
                clickHandler={() => clickHandler(idx)}
                page={"faq"}
              />
              {idx === faqItems.length - 1 && (
                <div className="hidden w-1 bg-white h-11 md:inline-block xl:hidden"></div>
              )}
            </div>
          ))}
        </div>
      </section>
    </SubLayout>
  );
};

export default GotQuestions;
