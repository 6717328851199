import React, { ReactElement } from "react";
import Head from "next/head";
import Hero from "@/components/landing-new/Hero";

import PathToHomeOwnership from "@/components/landing-new/PathToHomeOwnership";
import ContactBanner from "@/components/landing-new/ContactBanner";
import GotQuestions from "@/components/landing-new/GotQuestions";
import BudgetCalculator from "@/components/landing-new/BudgetCalculator";
import ReadyToMoveBanner from "@/components/landing-new/ReadyToMoveBanner";
import BenefitsOfKeyzyNow from "@/components/landing-new/BenefitsOfKeyzyNow";
import FlatsAndHousesToRent from "@/components/landing-new/FlatsAndHousesToRent";
import getConfig from "next/config";
import { MainLayout } from "@/containers/layout/NowLayout";

const KeyzyNow = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>Keyzy - Rent to buy a home, today!</title>
        <link rel="canonical" href="https://www.keyzy.com" />
        <meta
          name="description"
          content="Keyzy has homes available today that you can rent for now, and buy later, at a pre-agreed price, when you are ready."
        />
        <meta
          name="twitter:card"
          content="Keyzy has homes available today that you can rent for now, and buy later, at a pre-agreed price, when you are ready."
          key="twcard"
        />
        <meta name="twitter:creator" content="keyzyhomes" key="twhandle" />
        <meta property="og:url" content="/" key="ogurl" />
        <meta property="og:image" content="/now/meta-og-keyzy-now.png" key="ogimage" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content="Keyzy" key="ogsitename" />
        <meta property="og:title" content="Keyzy - Rent to buy a home, today!" key="ogtitle" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta
          property="og:description"
          content="Keyzy has homes available today that you can rent for now, and buy later, at a pre-agreed price, when you are ready."
          key="ogdesc"
        />
      </Head>
      <div className="bg-white">
        <Hero />
        <BudgetCalculator />
        <PathToHomeOwnership />
        <FlatsAndHousesToRent />
        <BenefitsOfKeyzyNow />
        <ReadyToMoveBanner />
        <GotQuestions />
        <div className="relative h-full bg-white w-full overflow-hidden">
          <ContactBanner />
        </div>
      </div>
    </>
  );
};

KeyzyNow.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <MainLayout theme="dark">{page}</MainLayout>
    </>
  );
};

export default KeyzyNow;

export async function getServerSideProps() {
  const { publicRuntimeConfig } = getConfig();
  if (publicRuntimeConfig.NEXT_ENABLE_NOW_PAGE === "1") {
    return {
      props: {},
    };
  } else {
    return {
      notFound: true,
    };
  }
}
