import "rc-slider/assets/index.css";

import { Props } from "../types";
import { formatPrice } from "@/utils/formatPrice";

const CalculatorMobile = ({ keyzyBudget }: Props) => {
  return (
    <div className="relative z-50 mt-9 ">
      <div className="flex flex-col items-start mb-5">
        <h3 className="text-sm font-bold uppercase">Max home budget</h3>
        <span className="my-3 text-2xl font-bold text-landing-cherry">
          {keyzyBudget?.indicativeBudget}
        </span>
        {/* <span className="text-base font-normal">{`Normal Rentals: ${formatPrice(1500)}`}</span> */}
      </div>
      <div className="flex flex-col items-start mb-5">
        <h3 className="text-sm font-bold uppercase">Monthly rent</h3>
        <span className="my-3 text-2xl font-bold text-landing-cherry">{keyzyBudget?.rent}</span>
        {/* <span className="text-base font-normal">{`Normal Rentals: ${formatPrice(1700)}+`}</span> */}
      </div>

      {/* <div className="w-full h-px mx-auto my-5 bg-landing-gray" /> */}

      <div className="flex flex-col items-start">
        <h3 className="text-sm font-bold uppercase">Deposit You Build</h3>
        <span className="my-3 text-2xl font-bold text-landing-cherry">{keyzyBudget?.deposit}</span>
        {/* <div className="flex flex-wrap items-center">
          <span className="mr-1 text-base font-normal">Normal Rentals: </span>
          <span className="text-base font-normal">Not an option!</span>
        </div> */}
      </div>
    </div>
  );
};

export default CalculatorMobile;
