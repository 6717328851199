import { useEffect, useState } from "react";
import { BudgetModel } from "@/components/landing-old/BudgetCalculator/types";
import { formatPrice } from "@/utils/formatPrice";

export function useNowCalculatorBudget(annualIncome: number) {
  const [keyzyBudget, setKeyzyBudget] = useState<BudgetModel & { rent: string }>();

  useEffect(() => {
    const houseBudget = annualIncome * 1.1 * 5;
    const rentPrice = (houseBudget / 12) * 0.05;
    const accumulatedSavings = rentPrice * 24;

    setKeyzyBudget({
      deposit: formatPrice(accumulatedSavings),
      rent: formatPrice(rentPrice),
      indicativeBudget: `${formatPrice(houseBudget)}`,
    });
  }, [annualIncome]);

  return [keyzyBudget] as const;
}
