import { Props } from "./types";

const Description = ({ className }: Props) => {
  return (
    <div className={`${className} xl:w-96`}>
      <h2 className="text-3xl font-bold md:mt-36 xl:mt-24 xl:text-5xl ">
        Rent your future home now with{" "}
        <span className="text-landing-cherry">100% of your rent saved</span> for your future deposit
      </h2>
      <div className="text-base mt-7">
        With Keyzy, enjoy <b>fixed rent for 2 years</b> and the{" "}
        <b>option to buy your home at a guaranteed price</b>, giving you the freedom to buy when
        you’re ready – all while building your deposit from day one.
      </div>
    </div>
  );
};

export default Description;
