import React from "react";
import { MainLayoutProps } from "./types";
import { useModalPopupContext } from "@/contexts/navigationContext/ModalPopupContext";
import Header from "@/components/landing-new/Header";
import Footer from "@/components/landing-new/Footer";

const MainLayout = ({ children, theme = "dark" }: MainLayoutProps): JSX.Element => {
  const { setShowModal } = useModalPopupContext();

  return (
    <>
      <div className={`flex flex-col items-center ${theme === "ivory" && "bg-landing-ivory"}`}>
        <Header theme={theme} setShowModal={setShowModal as any} />
        <main className="w-full">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
