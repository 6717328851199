import Image from "next/image";

import { Props } from "./types";

const Card = ({
  title,
  subtitle,
  footer,
  size = "big",
  image,
  onlyHeading,
}: Props): JSX.Element => {
  return (
    <div
      className={`flex flex-row lg:flex-col items-center lg:mx-20 ${
        size === "big" || size === "middle" ? "md:w-full xl:w-[339px]" : "md:w-48 xl:w-60"
      }`}
    >
      <div
        className={`relative w-16 h-16 min-w-[4rem] md:min-w-[5rem] lg:min-w-[7rem] mx-3 sm:mx-6 lg:mx-0 ${
          size === "big"
            ? "md:w-20 md:h-20 lg:w-20 lg:h-28 xl:w-52 xl:h-52"
            : size === "middle"
            ? "md:w-24 md:h-24 xl:w-32 xl:h-32 lg:mt-6 mt-0"
            : "md:w-16 md:h-20 xl:w-28 xl:h-32"
        }`}
      >
        <Image src={image} alt={title} layout="fill" objectFit="contain" />
      </div>

      <div className="flex flex-col justify-start text-left lg:text-center lg:items-center md:justify-center mx-2">
        {!onlyHeading && (
          <>
            <h3
              className={`text-landing-blue mb-4 leading-tight lg:leading-10 font-sans font-bold ${
                size === "big"
                  ? "md:text-2xl md:mb-5 xl:text-3xl xl:mb-6 lg:mt-5"
                  : size === "middle"
                  ? "md:font-sans md:font-bold text-lg mb-1.5 md:text-2xl md:mb-4 lg:text-3xl xl:mb-3 lg:mt-3"
                  : "md:font-sans md:font-bold md:text-md md:mb-4 xl:text-xl xl:mb-3 lg:mt-3"
              }`}
            >
              {title === "Nursing and Social Care" ? (
                <p>
                  Nursing and
                  <br className="hidden lg:block" />
                  <span className="inline-block lg:hidden">&nbsp;</span>
                  Social Care
                </p>
              ) : (
                title
              )}
            </h3>

            <p className="tracking-[-0.011em] text-left md:w-auto lg:text-center md:font-normal md:text-base lg:text-xl text-black !leading-tight">
              {subtitle}
            </p>
            {!!footer && (
              <p
                className={`text-lg ${
                  size === "middle" ? "text-landing-blue" : ""
                } font-semibold lg:mt-7 mt-1.5 md:mt-3`}
              >
                {footer}
              </p>
            )}
          </>
        )}
        {!!onlyHeading && (
          <>
            <h3
              className={`text-landing-blue leading-tight lg:text-[32px] lg:leading-10 font-serif font-bold
                md:text-2xl text-lg lg:mt-5`}
            >
              {title}
            </h3>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
