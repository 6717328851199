import "rc-slider/assets/index.css";
import Image from "next/image";

import { Props } from "../types";

const CalculatorDesktop = ({ keyzyBudget }: Props) => {
  return (
    <div className="relative z-40 w-full">
      <div className="w-full mt-6">
        <table className="w-full text-right table-fixed">
          <thead>
            <tr className="text-sm uppercase border-b bold border-landing-blue border-opacity-10">
              <th className="border-r border-landing-blue border-opacity-10"></th>
              <th className="p-3 w-[200px] border-r border-landing-blue border-opacity-10">
                Max home budget
              </th>
              <th className="p-3 border-r border-landing-blue border-opacity-10">Monthly rent</th>
              <th className="p-3">Deposit you build</th>
              {/* <th className="w-32 p-3 border-r border-landing-blue border-opacity-10">
                Agent fees
              </th> */}
            </tr>
          </thead>
          <tbody>
            <tr className="text-2xl border-b text-landing-cherry text-bold border-landing-blue border-opacity-10">
              <th className="text-center border-r border-landing-blue border-opacity-10">
                <Image
                  src="/shared/keyzy-horizontal-white.svg"
                  alt="Keyzy"
                  width="112"
                  height="28"
                />
              </th>
              <th className="px-3 py-6 border-r border-landing-blue border-opacity-10">
                {keyzyBudget?.indicativeBudget}
              </th>
              <th className="px-5 border-r border-landing-blue border-opacity-10">
                {keyzyBudget.rent}
              </th>
              <th className="">{keyzyBudget?.deposit}</th>
              {/* <th className="px-5 border-r border-landing-blue border-opacity-10">
                {keyzyBudget?.deposit}
              </th> */}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalculatorDesktop;
